/**
 * Define your [key, value] message translations.
 */
export default {
  'app.format.date': 'DD/MM/YYYY',

  'app.format.dateTime': 'DD/MM/YYYY HH:mm',

  'app.salesPortal.select': 'Selecionar',
  'app.salesPortal.remove': 'Excluir',
  'app.salesPortal.sendApproval': 'Enviar para aprovação',
  'app.salesPortal.placeComments': 'Comentário Livre...',
  'app.salesPortal.add': 'Adicionar',
  'app.salesPortal.approve': 'Aprovar',
  'app.salesPortal.reprove': 'Reprovar',
  'app.salesPortal.title': 'Bem vindo ao ',
  'app.salesPortal.subtitle': 'Use o menu à esqueda para ter acesso a todas as funcionalidadades.',
  'app.salesPortal.yes': 'Sim',
  'app.salesPortal.no': 'Não',
  'app.salesPortal.applyDiscount': 'Aplicar desconto de',
  'app.salesPortal.suspend': 'Suspender',
  'app.salesPortal.justify': 'Justificativa',
  'app.salesPortal.client': 'Cliente',
  'app.salesPortal.changeBackground': 'Alterar Plano de Fundo',
  'app.salesPortal.description': 'Descrição',
  'app.salesPortal.save': 'Salvar',
  'app.salesPortal.history': 'Histórico',
  'app.salesPortal.galery': 'Galeria',
  'app.salesPortal.saveAndChange': 'Salvar e Alterar',

  'app.changePass.title': 'Troque sua senha!',
  'app.changePass.send': 'Enviar',

  'app.userType.administrator': 'Administrador',
  'app.userType.commercial': 'Comercial',
  'app.userType.representative': 'Representante',

  'app.menu.home': 'Home',
  'app.menu.profile': 'Perfil',
  'app.menu.support': 'Suporte',
  'app.menu.administrative': 'Configurações',
  'app.menu.userSettings': 'Usuários',
  'app.menu.discountSettings': 'Descontos',
  'app.menu.rulesReward': 'Regras de Comissão',
  'app.menu.messageCool': 'Mensagem Legal',
  'app.menu.hierarchApproval': 'Hierarquias de Aprovação',
  'app.menu.manutencaoDeItens': 'Manutenção de Itens',
  'app.menu.redeploymentSettings': 'Transportadoras de Redespacho',
  'app.menu.suspendedCustomers': 'Clientes Suspensos',
  'app.menu.backgroundChange': 'Plano de Fundo',

  'app.menu.commercial': 'Comercial',
  'app.menu.representative': 'Representantes',
  'app.menu.discount': 'Desconto',
  'app.menu.order': 'Pedidos',

  'app.menu.report': 'Relatórios',
  'app.menu.clients': 'Clientes',
  'app.menu.orders': 'Pedidos',
  'app.menu.products': 'Itens de Venda',

  'app.userCrud.userCode': 'Matrícula',
  'app.userCrud.name': 'Nome',
  'app.userCrud.email': 'E-mail',
  'app.userCrud.securityGroupId': 'Grupo de acesso',
  'app.userCrud.representative': 'Representante',
  'app.userCrud.establishment': 'Estabelecimento',
  'app.userCrud.active': 'Ativo',
  'app.userCrud.password': 'Senha',
  'app.userCrud.confirmPassword': 'Confirme a Senha',
  'app.userCrud.oldPassword': 'Senha Atual',

  'app.redeploymentCrud.name':'Nome (*)',
  'app.redeploymentCrud.code':'Código',
  'app.redeploymentCrud.alias':'Nome Fantasia (*)',
  'app.redeploymentCrud.nature':'Natureza',
  'app.redeploymentCrud.phone':'Telefone',
  'app.redeploymentCrud.stateRegistration':'Inscrição Estadual (*)',
  'app.redeploymentCrud.cnpj':'CNPJ (*)',
  'app.redeploymentCrud.number':'Número',
  'app.redeploymentCrud.address':'Endereço (*)',
  'app.redeploymentCrud.complement':'Complemento',
  'app.redeploymentCrud.district':'Bairro (*)',
  'app.redeploymentCrud.cep':'CEP (*)',
  'app.redeploymentCrud.active':'Ativo',
  'app.redeploymentCrud.city':'Cidade (*)',
  'app.redeploymentCrud.state':'Estado (*)',

  'app.legalMessageCrud.client': 'Cliente (*)',
  'app.legalMessageCrud.message': 'Mensagem (*)',

  'app.hierarchApproval.level.1': 'Aprovador de Primeiro Nível',
  'app.hierarchApproval.level.2': 'Aprovador de Segundo Nível',
  'app.hierarchApproval.level.3': 'Aprovador de Terceiro Nível',
  'app.hierarchApproval.name': 'Nome Hierarquia',
  'app.hierarchApproval.user': 'Usuário',
  'app.hierarchApproval.startingTrack': 'Faixa Inicial',
  'app.hierarchApproval.finalTrack': 'Faixa Final',
  'app.hierarchApproval.hasSubstitute': 'Tem Substituto?',
  'app.hierarchApproval.validity': 'Vigência',
  'app.hierarchApproval.to': 'a',
  'app.hierarchApproval.add': 'Adicionar Nível',

  'app.representative.title': 'Parâmetros do Representante',
  'app.representative.supplier': 'Fornecedor (*)',
  'app.representative.selectDown': 'Selecione Abaixo (*): ',
  'app.representative.paymentCondition': 'Condições de Pagamento',
  'app.representative.approval': 'Aprovação',
  'app.representative.transporter': 'Transportadoras',
  'app.representative.sourceCustomer': 'Lista de Clientes',
  'app.representative.targetCustomer': 'Clientes Selecionados',

  'app.discountCrud.name': 'Nome',
  'app.discountCrud.formula': 'Fórmula (*)',
  'app.discountCrud.description': 'Descrição (*)',

  'app.customerReport.name': 'Nome',
  'app.customerReport.cnpj': 'CNPJ',
  'app.customerReport.stateRegistration': 'Inscrição Estadual',

  'app.salesPersonCrud.searchButton': 'Pesquisar',
  'app.salesPersonCrud.input': 'Termo de pesquisa',
  'app.salesPersonCrud.switch': 'Sales Person Não Cadastrado',

  'app.commercialOrder.searchButton': 'Pesquisar',
  'app.commercialOrder.input': 'Data',
  'app.commercialOrder.switch': 'Pendentes de Aprovação',
  'app.commercialOrder.warehouse': 'Organização',

  'app.reward.fixed': 'Comissão Fixa',
  'app.reward.general': 'Comissão Geral',
  'app.reward.reward': 'Comissão',

  'app.rewardFixed.client': 'Cliente',

  'app.representative.parameters': 'Parâmetros',
  'app.representative.client': 'Clientes',
  'app.rewardGeneral.minDiscount': 'Desconto Min.',
  'app.rewardGeneral.maxDiscount': 'Desconto Max.',

  'app.order.dataCustomer': 'Dados do Cliente',
  'app.order.dataOrder': 'Dados do Pedido',
  'app.order.dataItem': 'Dados do Item',
  'app.order.dataOrderTitle': 'DADOS DO PEDIDO',
  'app.order.itemOrder': 'ITENS DO PEDIDO',
  'app.order.priceOrder': 'COTAÇÃO',
  'app.order.historyOrder': 'HISTÓRICO',
  'app.order.notes': 'NOTAS',
  'app.order.modalApproveTitle': 'Comentário de Aprovação',
  'app.order.modalReproveTitle': 'Comentário de Reprovação',
  'app.order.modalCancelTitle': 'Comentário de Cancelamento',
  'app.order.modalDeleteTitle': 'Comentário de Exclusão',
  'app.order.pendingUpdates': 'Pendente de atualização',
  'app.order.waitingApproval': 'Aguardando aprovação',
  'app.order.orderApproval': 'Em Integração',
  'app.order.orderBilleding': 'Em Faturamento',
  'app.order.orderBilled': 'Faturado',
  'app.order.warning': 'Alerta!',
  'app.order.notLocal': 'O Cliente selecionado não possui Price List',
  'app.order.textWarning': 'O item selecionado tem um valor mínimo, e não está de acordo com o informado!',
  'app.order.textWarningLot': 'A quantidade do item selecionado deve ser múltiplo do Lote Mult Venda!',
  'app.order.textWarningInvalid': 'A quantidade do item selecionado é invalida!',
  'app.order.textDiscountInvalid': 'A Desconto do item selecionado é invalido!',
  'app.order.valueWarning': 'O valor de desconto está fora da sua permissão!',
  'app.order.clientName': 'Cliente',
  'app.order.phone': 'Telefone',
  'app.order.statusCredit': 'Status Crédito',
  'app.order.suframa': 'Código Suframa',
  'app.order.city': 'Cidade',
  'app.order.address': 'Endereço',

  'app.dataOrder.orderNumber': 'Pedido Portal',
  'app.dataOrder.orderOracleNumber': 'Pedido Oracle',
  'app.dataOrder.situation': 'Situação',
  'app.dataOrder.orderDate': 'Data do Pedido',
  'app.dataOrder.representative': 'Representante',
  'app.dataOrder.nature': 'Natureza',
  'app.dataOrder.natureDescription': 'Descrição Natureza',
  'app.dataOrder.paymentCodition': 'Condição de Pagamento (*)',
  'app.dataOrder.priceList': 'Tabela de preço',
  'app.dataOrder.establishment': 'Estabelecimento',
  'app.dataOrder.warehouse': 'Organização',
  'app.dataOrder.enterDate': 'Data de entrada',
  'app.dataOrder.billedDate': 'Data de faturamento',
  'app.dataOrder.deliveryDate': 'Data programada da entrega',
  'app.dataOrder.deviveryAddress': 'Endereço de entrega',
  'app.dataOrder.bearer': 'Portador',
  'app.dataOrder.discount': ' Tipo de desconto',
  'app.dataOrder.carrier': 'Transportador (*)',
  'app.dataOrder.freight': 'Frete',
  'app.dataOrder.OrderNumberOnClient': 'Número Pedido - Cliente (XML)',
  'app.dataOrder.redispatch': 'Redespacho',
  'app.dataOrder.obsNote': 'Observações (corpo da nota)',
  'app.dataOrder.obsInternal': 'Observações internas',

  'app.historyOrder.title': 'Histórico de aprovação',
  'app.historyOrder.status': 'Status: ',
  'app.historyOrder.dateApproval': 'Data: ',
  'app.historyOrder.comment': 'Comentário: ',
  'app.historyOrder.approver': 'Aprovador: ',


  'app.order.level.1': 'Primeiro Nível',
  'app.order.level.2': 'Segundo Nível',
  'app.order.level.3': 'Terceiro Nível',
  'app.order.level.5': 'Cancelado',

  'app.order.status.0': 'Aguardando aprovação',
  'app.order.status.1': 'Aprovado',
  'app.order.status.2': 'Reprovado',
  'app.order.status.5': 'Cancelado',

  'app.order.flowStatus.0': 'Pendente de Atualização',
  'app.order.flowStatus.1': 'Aguardando Aprovação',
  'app.order.flowStatus.2': 'Liberado',
  'app.order.flowStatus.3': 'Integrado',
  'app.order.flowStatus.6': 'Faturado Parcialmente',
  'app.order.flowStatus.4': 'Faturado',
  'app.order.flowStatus.5': 'Cancelado',
  'app.order.flowStatus.99': 'Reprovado',
  'app.order.flowStatus.semStatus': 'Sem Status',

  'app.order.selectSite': 'Selecione um local para começar',


  'app.order.orderNumber': 'Número do Pedido',
  'app.order.abbreviatedName': 'Nome Abreviado',
  'app.order.client': 'Clientes',
  'app.order.orderDate': 'Data do Pedido',
  'app.order.implantationDate': 'Data de Implantação',
  'app.order.deliveryDate': 'Data de Entrega',
  'app.order.NFDate': 'Data de Emissão NF',
  'app.order.pendingMyUpdate': 'Pendente da Minha Aprovação',
  'app.order.pendingUpdate': 'Pendente de Atualização',
  'app.order.pendingCommercialApproval': 'Pendente de Aprovação Comercial',
  'app.order.commercialReproved': 'Reprovado Comercial',
  'app.order.creditNotApproved': 'Crédito Não Aprovado',
  'app.order.released': 'Liberado',
  'app.order.integration': 'Integrado',
  'app.order.billed': 'Faturado / Faturado Parcial',
  'app.order.canceled': 'Cancelado',
  'app.order.filter': 'Aplicar',
  'app.order.clientId': 'Código do Cliente',

  'app.order.addDiscount': 'Deseja replicar o último desconto alterado?',
  'app.order.confirmTitle': 'Deseja enviar para aprovação o pedido?',
  'app.order.confirmDescription': 'Todas as informações contidas neste pedido são de responsabilidade do solicitante!',
  'app.order.send': 'Enviar',
  'app.order.cancel': 'Cancelar',

  'app.itemPrice.rowPromotion': 'Linha com tabela promocional',
  'app.itemPrice.rowBigDiscount': 'Linha com desconto maior que o ultimo nível',

  'app.order.export': 'Exportar',

  'app.support.subject': 'Assunto',
  'app.userCrud.reason': 'Motivo',

  'app.priceOrder.print': 'Gerar PDF',
  'app.priceOrder.excel': 'Gerar Excel',
  'app.priceOrder.email': 'Email',
  'app.priceOrder.messageLegal': 'Mensagem Legal: ',
  'app.priceOrder.titleTop': 'WABTEC BRASIL FABRICACAO E MANUTENCAO DE EQUIPAM. LTDA',
  'app.priceOrder.titleMid': 'RUA 19 DE NOVEMBRO, 305 - (16) 3244-5000',
  'app.priceOrder.titleBottom': 'CEP 15910-000 - Monte Alto - Estado de São Paulo',
  'app.priceOrder.orderNumber': 'Pedido de Venda nº: ',
  'app.priceOrder.implantationDate': 'Dt. Implant: ',
  'app.priceOrder.buyer': 'Comprador: ',
  'app.priceOrder.cgf': 'CNPJ: ',
  'app.priceOrder.address': 'Endereço: ',
  'app.priceOrder.city': 'Cidade: ',
  'app.priceOrder.zipCode': 'CEP: ',
  'app.priceOrder.transporter': 'Transportador: ',
  'app.priceOrder.seller': 'Vendedor: ',
  'app.priceOrder.representative': 'Representante: ',
  'app.priceOrder.code': 'Código: ',
  'app.priceOrder.stateRegistration': 'Insc. Estadual: ',
  'app.priceOrder.district': 'Bairro: ',
  'app.priceOrder.state': 'Estado: ',
  'app.priceOrder.phoneFax': 'Fone/FAX: ',
  'app.priceOrder.billedDate': 'Dt. Pedido: ',
  'app.priceOrder.DeliveryDate': 'Dt. Entrega: ',
  'app.priceOrder.paymentCondition': 'Cond. Pag.: ',
  'app.priceOrder.grossTotal': 'Total Bruto Itens: ',
  'app.priceOrder.totalDiscount': 'Total Descontos: ',
  'app.priceOrder.discountPercentage': '(%)Desconto: ',
  'app.priceOrder.netValue': 'Total Liquido Itens s/Impostos: ',
  'app.priceOrder.IPI': 'Total IPI: ',
  'app.priceOrder.ST': 'Total STs: ',
  'app.priceOrder.STIPI': 'Total Geral Itens com IPI/ST: ',
  'app.priceOrder.internalComment': 'Observações Internas: ',
  'app.priceOrder.comments': 'Observações: ',
  'app.priceOrder.bottomInformation': 'As mercadorias viajam por conta e risco do comprador. / As mercadorias estão sujeitas ao IPI. / Pedido sujeito a confirmação. / Desconto de: ',
  'app.priceOrder.labelEmail': 'Email: ',
  'app.priceOrder.labelBuyer': 'Comprador(es) ',
  'app.priceOrder.labelSeller': 'Vendedor ',
  'app.priceOrder.user': 'Usuário: ',

  'app.salesPortal.searchItem': 'Pesquisa Item',

  'app.redeployment.name': 'Nome: ',
  'app.redeployment.cnpj': 'CNPJ: ',
  'app.redeployment.stateRegister': 'Inscrição Estadual: ',
  'app.redeployment.address': 'Endereço: ',
  'app.redeployment.district': 'Bairro: ',
  'app.redeployment.city': 'Cidade: ',
  'app.redeployment.state': 'Estado: ',
  'app.redeployment.cep': 'CEP: ',
  'app.redeployment.phone': 'Telefone: ',

  "SALES_PERSON_DEFINIDO_SUCESSO": "Sales Person definido com sucesso",
  "DADOS_ATUALIZADOS_SUCESSO": "Dados atualizados com sucesso",
  "ALTERACAO_REALIZADA_SUCESSO": "Alteração realizada com sucesso",

  // Erros sistema backend

  'TOKEN_NOT_INFORMED': 'Token não informado',
  'ACCESS_INVALID': 'Acesso inválido',
  'TOKEN_EXPIRED': 'Token expirado!',
  'INVALID_ACCESS': 'Acesso inválido',
  'USER_INVALID': 'Usuário e/ou senha inválidos',
  'TOKEN_INVALID': 'Token inválido, logue novamente',
  'ERRO_VALIDACAO_DADOS': 'Um ou mais dados não foram informados',
  'ERRO_CRIACAO_HIERARQUIA': 'Erro ao criar Hierarquia',
  'HIERARQUIA_NAO_ENCONTRADO': 'Hierarquia não encontrada',
  'ERRO_ATUALIACAO_HIERARQUIA': 'Erro ao atualizar hierarquia',
  'TRANSPORTADORA_NAO_ENCONTRADA': 'Transportadora não encontrada',
  'CLIENTE_NAO_ENCONTRADO': 'Cliente não encontrado',
  'IS_NOT_YOUR_CLIENT': 'Você não tem permissão nesse cliente',
  'CLIENT_NOT_REPRESENTATIVE': 'Cliente não possui representante',
  'REPRESENTANTE_SEM_PERMISSAO': 'Você não tem permissão nesse cliente',
  'DESCONTO_NAO_ENCONTRADO': 'Desconto não encontrado',
  'CLIENTE_JA_POSSUI_COMISSAO': 'Cliente já possui comissão',
  'COMISSAO_FIXA_NAO_ENCONTRADO': 'Comissão não encontrada',
  'COMISSAO_GERAL_NAO_ENCONTRADO': 'Comissão não encontrada',
  'CLIENTE_JA_POSSUI_MENSAGEM': 'Cliente já possui menssagem legal',
  'MENSAGEM_LEGAL_NAO_ENCONTRADO': 'Comissão não encontrada',
  'ERRO_DOWNLOAD_NOTA': 'Erro ao realizar download da Nota',
  'ORDEM_NAO_ENCONTRADO': 'Ordem não encontrada',
  'SEM_PERMISSAO_SALVAR': 'Você não possui permissão para salvar',
  'ORDER_NOT_UPDATE': 'Você não possui permissão para atualizar',
  'ERROR_SEND_APPROVAL_NOT_ITEM': 'Pedido não pode ser enviada para aprovação sem produto',
  'ERROR_UPDATE_ORDER': 'Erro ao atualizar pedido',
  'NOT_CANCEL_PERMISSION': 'Erro ao cancelar o pedido',
  'ERROR_REMOVE_ORDER': 'Erro ao remover o pedido',
  'METODO_PAGAMENTO_NAO_ENCONTRADO': 'Termo de pagamento não encontrado',
  'CLIENTE_SEM_PRICELIST': 'Cliente não possui lista de preço cadastrada',
  'PRODUTO_NAO_ENCONTRADO': 'Produto não existe na base',
  'REPRESENTANTE_NAO_ENCONTRADO': 'Representante não encontrado',
  'CLIENTE_JA_CADASTRADO': 'Cliente já possui em outro representante',
  'SALES_PERSON_NAO_ENCONTRADO': 'Sales Person não localizado',
  'FORNECEDOR_NAO_ENCONTRADO': 'Fornecedor não localizado',
  'SALES_PERSON_NAO_CADASTRADO': 'Sales Person não localizado',
  'ERRO_DADO_WAREHOUSE': 'Warehouse não localizado',
  'ERRO_CALCULO_IMPOSTO': 'Erro ao realizar o cálculo de imposto, tente novamente!',
  'WAREHOUSE_NAO_ENCONTRADO': 'Warehouse não localizado',
  'ERRO_CRIACAO_ESTABELECIMENTO': 'Erro ao criar estabelecimento',
  'ESTABELECIMENTO_NAO_ENCONTRADO': 'Estabelecimento não encontrado',
  'EMAIL_PASS_MISSINGED': 'Usuário e/ou senha não informados',
  'PASSWORD_INCORRECT': 'Usuário e/ou senha inválidos',
  'NOT_PERMISSION': 'Usuário sem permissão',
  'ERROR_MAIL_INTERNO': 'Erro ao enviar e-mail',
  'DADOS_JA_UTILIZADOS': 'Dados informados já utilizados',
  'USUARIO_NAO_ENCONTRADO': 'Usuário não localizado',
  'ERRO_TIPO_FORMULARIO': 'Tipo de formulário não informado',
  'ERRO_AUTENTICACAO_SENHA': 'Usuário e/ou senha inválidos',
  'TIPO_FORMULARIO_INVALIDO': 'Tipo de formulário inválido',
  'ERRO_API_IMPOSTO': 'A API de imposto não respondeu, os valores foram Zerados!',
  'ACCOUNT_NUMBER_NOT_EXIST': 'Cliente não possui um Número de Conta cadastrado!',
  'ERRO_INTEGRACAO': 'Houve um erro ao realizar a integração!',
  'ERRO_ORDER_TYPE': 'Cliente sem Order Type cadastrado!',
  'CARRIER_SERVICE_NOT_EXIST': 'Transportador não possuir Code Service cadastrado!',
  'NO_CUSTOMER_MAS_FOUND' : 'Não foi possível localizar este cliente na tabela CUSTOMER_MAS. Por favor, entre em contato com o administrador do sistema para suporte.',



  // Sucesso sistema backend

  'HIERARQUIA_CRIADO_SUCESSO': 'Hierarquia criada com sucesso!',
  'HIERARQUIA_ATUALIZADA_SUCESSO': 'Hierarquia atualizada com sucesso',
  'HIERARQUIA_ATIVADO_SUCESSO': 'Hierarquia Ativada',
  'HIERARQUIA_DESATIVADO_SUCESSO': 'Hierarquia Desativada',
  'DESCONTO_CRIADO_SUCESSO': 'Desconto criado com sucesso!',
  'DESCONTO_ATIVADO_SUCESSO': 'Desconto Ativado',
  'DESCONTO_DESATIVADO_SUCESSO': 'Desconto Desativado',
  'COMISSAO_FIXA_CRIADO_SUCESSO': 'Comissão Fixa criada com sucesso',
  'COMISSAO_FIXA_REMOVIDA_SUCESSO': 'Comissão Fixa removida com sucesso',
  'COMISSAO_FIXA_ATIVADO_SUCESSO': 'Comissão Fixa ativada',
  'COMISSAO_FIXA_DESATIVADO_SUCESSO': 'Comissão Fixa desativada',
  'COMISSAO_GERAL_CRIADO_SUCESSO': 'Comissão criada com sucesso',
  'COMISSAO_GERAL_ATUALIZADO_SUCESSO': 'Comissão atualizada com sucesso',
  'COMISSAO_GERAL_ATIVADO_SUCESSO': 'Comissão ativada',
  'COMISSAO_GERAL_DESATIVADO_SUCESSO': 'Comissão desativada',
  'MENSAGEM_LEGAL_CRIADO_SUCESSO': 'Mensagem Legal criada com sucesso',
  'MENSAGEM_LEGAL_ATIVADO_SUCESSO': 'Mensagem Legal ativada',
  'MENSAGEM_LEGAL_DESATIVADO_SUCESSO': 'Mensagem Legal desativada',
  'ORDEM_ENVIADA_INTEGRACAO': 'Pedido enviado para integração!',
  'ORDER_UPDATED_SUCCESSFULLY': 'Pedido atualizado',
  'ORDER_CANCELED_SUCCESSFULLY': 'Pedido cancelado',
  'ORDER_REMOVE_SUCCESSFULLY': 'Pedido removido',
  'ORDEM_APROVADA_SUCESSO': 'Pedido aprovado',
  'ORDER_REPROVADA_SUCESSO': 'Pedido reprovado',
  'REPRESENTANTE_CRIADO_SUCESSO': 'Representante criado com sucesso',
  'REPRESENTANTE_ATIVADO_SUCESSO': 'Representante ativado',
  'REPRESENTANTE_DESATIVADO_SUCESSO': 'Representante desativado',
  'CLIENTES_ATUALIZADOS': 'Clientes atualizados com sucesso',
  'ESTABELECIMENTO_CRIADO_SUCESSO': 'Estabelecimento criado com sucesso',
  'ESTABELECIMENTO_ATIVADO_SUCESSO': 'Estabelecimento ativado',
  'ESTABELECIMENTO_DESATIVADO_SUCESSO': 'Estabelecimento desativado',
  'EMAIL_SUCESSO': 'Email enviado com sucesso!',
  'RESUMO_DOS_VALORES': 'resumo dos valores',
  'ALTERACAO_PODE_PERDER':'É possível que as alterações feitas não sejam salvas.',
  'CONFIRMAR_CANCELAMENTO':'Você tem certeza que deseja CANCELAR esse pedido?',
  'CONFIRMAR_EXCLUSAO':'Você tem certeza que deseja EXCLUIR esse pedido?',
  'ALERTA_COMENTARIO':'Comentário não pode ser vazio!',
  'CONFIRMAR_ALTERAR_ORGANIZACAO':'Trocar a organização excluirá todos os itens vinculados ao pedido. Deseja prosseguir?',
  'REDEPLOYMENT_ATIVADO_SUCESSO': 'Transportadora de Redespacho ativada',
  'REDEPLOYMENT_DESATIVADO_SUCESSO': 'Transportadora de Redespacho desativada',
};
