import { apiClient, apiClientBackground } from '../apiClient';
export interface ImageData {
    type: string;
    data: number[];
}

export interface Image {
    active: boolean;
    description: string;
    id: number;
    image_data: ImageData;
    image_name: string;
    image_size: number;
    image_type: string;
    upload_date: string;
    usercode: string;
    hidden: boolean;
}

export interface BackgroundHistory {
    id: number;
    usercode: string;
    change_date: string;
    previous_image_id: number;
    new_image_id: number;
    justification?: string;
}

export const createImageUrl = (imageData: ImageData, imageType: string) => {
    const data = new Uint8Array(imageData.data);
    const blob = new Blob([data], { type: imageType});
    return URL.createObjectURL(blob);
};

export const onAllImages = () => apiClient.get<Image[]>('/portal/images/');

export const onStoreImage = (formData: FormData) =>
    apiClient.post<any>(`portal/images/store`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
export const getActiveBackground = async () =>
    apiClient.get<Image>('/portal/public/getActiveBackground');

export const getActiveBackgroundUrl = async () =>{
    const img = await apiClientBackground.get<Image>('/portal/public/getActiveBackground');
    if(img){
        return createImageUrl(img.image_data, img.image_type)
    }
    return '';
}

export const onActiveBackground = async (idImage: string, values: {
    userCode: string;
    justify: string
}) =>
    apiClient.put(`/portal/images/activeBackground/${idImage}`,values);

export const onDeleteBackground = async (idImage: string, values: {
    userCode: string;
    justify: string
}) =>
    apiClient.put(`/portal/images/deleteBackground/${idImage}`,values);

export const getBackgroundHistory = () => apiClient.get<BackgroundHistory[]>('/portal/images/getBackgroundHistory');
